import { useTranslation } from 'react-i18next';
import { cn } from '@/lib/utils';
import { useTheme } from '@/lib/providers/theme-provider';
import { MenubarItem, MenubarSub, MenubarSubContent, MenubarSubTrigger } from '../ui/menubar';
import { trpc } from '@/lib/providers/trpc';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '../icons/spinner';
import { PersonIcon, GlobeIcon, SunIcon, ExitIcon, MoonIcon } from '@radix-ui/react-icons';
import { Link } from '@/components/links/Link';
import { useQueryClient } from '@tanstack/react-query';

export function LanguageSettingsMenu() {
	const { t, i18n } = useTranslation();

	return (
		<MenubarSub>
			<MenubarSubTrigger>
				<span className="flex items-center gap-x-2">
					<GlobeIcon />
					{t('language')}
				</span>
			</MenubarSubTrigger>
			<MenubarSubContent>
				<MenubarItem onClick={() => i18n.changeLanguage('en')} className={cn('cursor-pointer', i18n.language === 'en' && 'font-bold')}>
					{t('language_english')}
				</MenubarItem>
				<MenubarItem onClick={() => i18n.changeLanguage('no')} className={cn('cursor-pointer', i18n.language === 'no' && 'font-bold')}>
					{t('language_norwegian')}
				</MenubarItem>
			</MenubarSubContent>
		</MenubarSub>
	);
}

export function ThemeSettingsMenu() {
	const { theme, setTheme } = useTheme();
	const { t } = useTranslation();

	return (
		<MenubarSub>
			<MenubarSubTrigger>
				<span className="flex items-center gap-x-2">
					{(theme === 'light' || theme === 'system') && <SunIcon />}
					{theme === 'dark' && <MoonIcon />}
					{t('theme')}
				</span>
			</MenubarSubTrigger>
			<MenubarSubContent>
				<MenubarItem onClick={() => setTheme('light')} className={cn('cursor-pointer', theme === 'light' && 'font-bold')}>
					{t('theme_light')}
				</MenubarItem>
				<MenubarItem onClick={() => setTheme('dark')} className={cn('cursor-pointer', theme === 'dark' && 'font-bold')}>
					{t('theme_dark')}
				</MenubarItem>
				<MenubarItem onClick={() => setTheme('system')} className={cn('cursor-pointer', theme === 'system' && 'font-bold')}>
					{t('theme_system')}
				</MenubarItem>
			</MenubarSubContent>
		</MenubarSub>
	);
}

export function ProfileButton() {
	const { t } = useTranslation();
	return (
		<>
			<MenubarItem className="flex cursor-pointer flex-row items-center gap-2" asChild>
				<Link to="/user/profile">
					<PersonIcon />
					{t('user_profile')}
				</Link>
			</MenubarItem>
		</>
	);
}

export function LogoutButton() {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const { mutate, isLoading } = trpc.usersLogout.useMutation({
		onSuccess: async () => {
			await queryClient.cancelQueries();
			queryClient.removeQueries();
			navigate('/auth/login');
		},
		onError: async () => {
			navigate('/auth/login');
		},
	});

	return (
		<MenubarItem onClick={() => mutate()} className="flex cursor-pointer flex-row items-center gap-2">
			{isLoading && <Spinner />}
			<span className="flex items-center gap-x-2">
				<ExitIcon />
				{t('logout')}
			</span>
		</MenubarItem>
	);
}
