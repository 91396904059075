import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';

import { cn } from '@/lib/utils';
import { type VariantProps, cva } from 'class-variance-authority';
import { Spinner } from '../icons/spinner';
import type { TRPCError } from '@/lib/providers/trpc';

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Overlay>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>>(
	({ className, ...props }, ref) => (
		<DialogPrimitive.Overlay
			ref={ref}
			className={cn(
				'fixed inset-0 z-50 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
				className,
			)}
			{...props}
		/>
	),
);
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const dialogContentVariants = cva(
	'pointer-events-auto fixed z-50 flex flex-col gap-4 border bg-background p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 sm:rounded-lg',
	{
		variants: {
			size: {
				default: 'w-full max-w-lg',
				dynamic: 'h-full w-full md:h-auto md:w-auto',
				full: 'h-[calc(100%-40px)] w-[calc(100%-40px)]',
			},
		},
		defaultVariants: {
			size: 'default',
		},
	},
);

interface DialogContentProps extends VariantProps<typeof dialogContentVariants> {
	isLoading?: boolean;
	error?: Error | TRPCError | null;
}

const DialogContent = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Content> & DialogContentProps,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & DialogContentProps
>(({ className, children, size, isLoading, error, ...props }, ref) => (
	<div className="pointer-events-none fixed inset-0 z-50 flex items-center justify-center">
		<DialogOverlay />
		<DialogPrimitive.Content ref={ref} className={cn(dialogContentVariants({ size }), className)} {...props}>
			{isLoading && (
				<div className="flex h-full w-full items-center justify-center">
					<Spinner size="sm" />
				</div>
			)}
			{error && (
				<div className="flex h-full w-full items-center justify-center">
					<span className="text-destructive">{error.message}</span>
				</div>
			)}
			{!isLoading && !error && children}
			<DialogPrimitive.Close data-testid="dialog-close-button" className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
				<Cross2Icon className="h-4 w-4" />
				<span className="sr-only">Close</span>
			</DialogPrimitive.Close>
		</DialogPrimitive.Content>
	</div>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={cn('spdace-y-1.5 flex flex-col text-center sm:text-left', className)} {...props} />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
	<div className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)} {...props} />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = React.forwardRef<React.ElementRef<typeof DialogPrimitive.Title>, React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>>(
	({ className, ...props }, ref) => (
		<DialogPrimitive.Title ref={ref} className={cn('text-lg font-semibold leading-none tracking-tight', className)} {...props} />
	),
);
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
	React.ElementRef<typeof DialogPrimitive.Description>,
	React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => <DialogPrimitive.Description ref={ref} className={cn('text-sm text-muted-foreground', className)} {...props} />);
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export { Dialog, DialogPortal, DialogOverlay, DialogTrigger, DialogClose, DialogContent, DialogHeader, DialogFooter, DialogTitle, DialogDescription };
