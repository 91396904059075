/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { HTMLAttributes, useCallback, useEffect, useMemo, useRef, useState } from 'react';
// @ts-expect-error unable to find correct types
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate, RenderElementProps, ReactEditor, useSelected, useFocused } from 'slate-react';
import { Editor, Transforms, createEditor, Descendant, Element as SlateElement, BaseEditor, Range } from 'slate';
import { HistoryEditor, withHistory } from 'slate-history';
import { cn } from '@/lib/utils';

import { SlateButton, SlateToolbar, SlatePortal } from './rich-text-editor-primitives';
import { IconProps } from '@radix-ui/react-icons/dist/types';
import {
	AlignCenterIcon,
	AlignJustifyIcon,
	AlignLeftIcon,
	AlignRightIcon,
	BoldIcon,
	HeadingIcon,
	ItalicIcon,
	Link2Icon,
	Link2OffIcon,
	ListOrderedIcon,
	LucideIcon,
	QuoteIcon,
	UnderlineIcon,
} from 'lucide-react';
import { ListBulletIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next';
import { Separator } from '../ui/separator';
import { TypographyBlockquote, TypographyMuted, TypographyP, TypographyH4 } from '../ui/typography';
import { ScrollArea } from '../ui/scroll-area';
import { useSystemVariable, useSystemVariables } from '@/hooks/useSystemVariable';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '../ui/dialog';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Portal } from '@radix-ui/react-portal';

const HOTKEYS = {
	'mod+b': 'bold',
	'mod+i': 'italic',
	'mod+u': 'underline',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

interface Props {
	initialValue?: object | object[];
	readOnly?: boolean;
	onChange?: (value: Record<string, object>) => void;
	disableMentions?: boolean;
	disableFormatting?: boolean;
}

const MENTION_REGEXP = /#(\w*)$/;

export function RichTextEditor({ initialValue, onChange, readOnly = false, disableMentions = false, disableFormatting = false }: Props) {
	const { t } = useTranslation();
	const ref = useRef<HTMLDivElement>(null);
	const [target, setTarget] = useState<Range | null>();
	const [index, setIndex] = useState(0);
	const [search, setSearch] = useState('');
	const renderElement = useCallback((props: RenderElementProps) => <Element {...props} readOnly={readOnly} />, [readOnly]);
	const renderLeaf = useCallback((props: LeafProps) => <Leaf {...props} />, []);
	const editor = useMemo(() => withInlines(withMentions(withReact(withHistory(createEditor())), disableMentions)), [disableMentions]);

	const { data: mentions = [] } = useSystemVariables(!disableMentions);

	const chars = mentions.filter((c) => c.id.toLowerCase().startsWith(search.toLowerCase())).slice(0, 10);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			// Regular hotkeys
			// Disable hotkeys if formatting is disabled
			if (!disableFormatting) {
				for (const hotkey in HOTKEYS) {
					// handles inline elements (hyperlinks) https://github.com/ianstormtaylor/slate/blob/8f2ad02db32f348eb9499e8db1e46d1b705d4d5d/site/examples/inlines.tsx#L74
					if (editor.selection && isHotkey('left', event)) {
						event.preventDefault();
						Transforms.move(editor, { unit: 'offset', reverse: true });
						return;
						// handles inline elements (hyperlinks) https://github.com/ianstormtaylor/slate/blob/8f2ad02db32f348eb9499e8db1e46d1b705d4d5d/site/examples/inlines.tsx#L74
					} else if (isHotkey('right', event)) {
						event.preventDefault();
						Transforms.move(editor, { unit: 'offset' });
						return;
						// handles rest
					} else if (isHotkey(hotkey, event)) {
						event.preventDefault();
						const mark = HOTKEYS[hotkey as keyof typeof HOTKEYS];
						toggleMark(editor, mark);
					}
				}
			}

			// Mentions
			if (!readOnly && !disableMentions && mentions.length > 0 && target && chars.length > 0) {
				switch (event.key) {
					case 'ArrowDown': {
						event.preventDefault();
						const prevIndex = index >= chars.length - 1 ? 0 : index + 1;
						setIndex(prevIndex);
						break;
					}
					case 'ArrowUp': {
						event.preventDefault();
						const nextIndex = index <= 0 ? chars.length - 1 : index - 1;
						setIndex(nextIndex);
						break;
					}
					case 'Tab':
					case 'Enter': {
						event.preventDefault();
						Transforms.select(editor, target);
						insertMention(editor, chars[index].id);
						setTarget(null);
						break;
					}
					case 'Escape':
						event.preventDefault();
						setTarget(null);
						break;
				}
			}
		},
		[chars, editor, index, mentions.length, target, readOnly, disableMentions, disableFormatting],
	);

	useEffect(() => {
		if (!readOnly && target && chars.length > 0) {
			const el = ref.current;
			const domRange = ReactEditor.toDOMRange(editor, target);
			const rect = domRange.getBoundingClientRect();
			if (el) {
				el.style.top = `${rect.top + window.scrollY + 24}px`;
				el.style.left = `${rect.left + window.scrollX}px`;
			}
		}
	}, [chars.length, editor, index, search, target, readOnly]);

	return (
		<div className="relative flex w-full flex-col">
			<div
				className={cn(
					'prose prose-slate max-w-full rounded-md dark:prose-invert prose-headings:mt-0 prose-p:mb-0 prose-p:mt-0',
					!readOnly && 'border border-primary/20 p-2',
				)}
			>
				<Slate
					editor={editor}
					initialValue={initialValue as Descendant[]}
					onChange={(value) => {
						const { selection } = editor;

						if (!readOnly && !disableMentions && mentions.length > 0 && selection && Range.isCollapsed(selection)) {
							const [start] = Range.edges(selection);
							const wordBefore = Editor.before(editor, start, { unit: 'word' });
							const before = wordBefore && Editor.before(editor, wordBefore);
							const beforeRange = before && Editor.range(editor, before, start);
							const beforeText = beforeRange && Editor.string(editor, beforeRange);
							const beforeMatch = beforeText && beforeText.match(MENTION_REGEXP);
							const after = Editor.after(editor, start);
							const afterRange = Editor.range(editor, start, after);
							const afterText = Editor.string(editor, afterRange);
							const afterMatch = afterText.match(/^(\s|$)/);

							if (beforeMatch && afterMatch) {
								setTarget(beforeRange);
								setSearch(beforeMatch[1]);
								setIndex(0);
								return;
							}
						}

						//@ts-expect-error unable to find correct types
						onChange?.(value);
						setTarget(null);
					}}
				>
					{!readOnly && (
						<SlateToolbar>
							<div className="flex h-4 w-full flex-row items-center gap-x-2">
								{!disableFormatting && (
									<>
										<MarkButton format="bold" Icon={BoldIcon} />
										<MarkButton format="italic" Icon={ItalicIcon} />
										<MarkButton format="underline" Icon={UnderlineIcon} />

										<Separator orientation="vertical" className="mx-1 h-full" />

										<BlockButton format="heading" Icon={HeadingIcon} />
										<BlockButton format="block-quote" Icon={QuoteIcon} />
										<Separator orientation="vertical" className="mx-1 h-full" />
										<LinkButton />
										<RemoveLinkButton />
										<Separator orientation="vertical" className="mx-1 h-full" />

										<BlockButton format="numbered-list" Icon={ListOrderedIcon} />
										<BlockButton format="bulleted-list" Icon={ListBulletIcon} />

										<Separator orientation="vertical" className="mx-1 h-full" />

										<BlockButton format="left" Icon={AlignLeftIcon} />
										<BlockButton format="center" Icon={AlignCenterIcon} />
										<BlockButton format="right" Icon={AlignRightIcon} />
										<BlockButton format="justify" Icon={AlignJustifyIcon} />

										<Separator orientation="vertical" className="mx-1 h-full" />
									</>
								)}

								{mentions.length > 0 && !disableMentions && <MentionButton setIndex={setIndex} setSearch={setSearch} setTarget={setTarget} />}
							</div>
						</SlateToolbar>
					)}
					<Editable
						readOnly={readOnly}
						renderElement={renderElement}
						//@ts-ignore
						renderLeaf={renderLeaf}
						placeholder={readOnly ? undefined : t('rich_text_placeholder')}
						spellCheck
						autoFocus
						rows={5}
						cols={5}
						style={{
							minHeight: readOnly ? undefined : '160px',
							maxHeight: readOnly ? undefined : '400px',
						}}
						className="overflow-auto outline-none [&>*:first-child]:mt-0"
						onKeyDown={onKeyDown}
					/>
					{mentions.length > 0 && !disableMentions && target && chars.length > 0 && (
						<SlatePortal>
							<div
								ref={ref}
								className="shadow-m pointer-events-auto absolute z-50 rounded-md border border-primary/20 bg-background p-1"
								data-testid="mentions-portal"
							>
								<ScrollArea
									className="z-100 h-72"
									onWheel={(e) => {
										// Fixes scrolling issues when rich text editor is used within a dialog
										// https://github.com/radix-ui/primitives/issues/1159
										e.stopPropagation();
									}}
								>
									{chars.map((char, i) => (
										<div
											key={char.id}
											onClick={() => {
												Transforms.select(editor, target);
												insertMention(editor, char.id);
												setTarget(null);
											}}
											className={cn(
												'max-w-sm cursor-pointer rounded-sm px-2 py-1 text-primary hover:bg-primary/20',
												i === index ? 'bg-primary/20 hover:bg-primary/20' : 'bg-background',
											)}
										>
											<TypographyP className="font-bold">{char.id}</TypographyP>
											{char.value && <TypographyMuted className="line-clamp-2 text-sm">{`${char.value}`}</TypographyMuted>}
										</div>
									))}
								</ScrollArea>
							</div>
						</SlatePortal>
					)}
				</Slate>
			</div>
		</div>
	);
}

export type CustomText = {
	bold?: boolean;
	italic?: boolean;
	code?: boolean;
	text: string;
};
type MentionElement = {
	type: 'mention';
	character: string;
	children: CustomText[];
	displayName?: string;
};

const insertMention = (editor: Editor, character: string, displayName?: string) => {
	const mention: MentionElement = {
		type: 'mention',
		character,
		displayName: displayName,
		children: [{ text: '' }],
	};
	Transforms.insertNodes(editor, mention);
	Transforms.move(editor);
};

const withMentions = (editor: BaseEditor & HistoryEditor & ReactEditor, disable: boolean) => {
	// Just return the base editor if mentions are disabled
	if (disable) return editor;

	const { isInline, isVoid, markableVoid } = editor;

	editor.isInline = (element: any) => {
		return element.type === 'mention' ? true : isInline(element);
	};
	editor.isVoid = (element: any) => {
		return element.type === 'mention' ? true : isVoid(element);
	};

	editor.markableVoid = (element: any) => {
		return element.type === 'mention' || markableVoid(element);
	};

	return editor;
};

const withInlines = (editor: BaseEditor & HistoryEditor & ReactEditor) => {
	const { insertData, insertText, isInline, isElementReadOnly, isSelectable } = editor;

	editor.isInline = (element: any) => ['link'].includes(element.type) || isInline(element);

	editor.isElementReadOnly = (element: any) => isElementReadOnly(element);

	editor.isSelectable = (element: any) => isSelectable(element);

	editor.insertText = (text) => {
		if (text && isUrl(text)) {
			wrapLink(editor, text);
		} else {
			insertText(text);
		}
	};

	editor.insertData = (data) => {
		const text = data.getData('text/plain');

		if (text && isUrl(text)) {
			wrapLink(editor, text);
		} else {
			insertData(data);
		}
	};

	return editor;
};

const insertLink = (editor: Editor, url: string) => {
	if (editor.selection) {
		wrapLink(editor, url);
	}
};

const isLinkActive = (editor: Editor) => {
	const [link] = Editor.nodes(editor, {
		match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === 'link',
	});
	return !!link;
};

const unwrapLink = (editor: Editor) => {
	Transforms.unwrapNodes(editor, {
		match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as any).type === 'link',
	});
};

const wrapLink = (editor: Editor, url: string) => {
	if (isLinkActive(editor)) {
		unwrapLink(editor);
	}

	const { selection } = editor;
	const isCollapsed = selection && Range.isCollapsed(selection);
	const link: LinkElement = {
		type: 'link',
		url,
		children: isCollapsed ? [{ text: url }] : [],
	};

	if (isCollapsed) {
		Transforms.insertNodes(editor, link);
	} else {
		Transforms.wrapNodes(editor, link, { split: true });
		Transforms.collapse(editor, { edge: 'end' });
	}
};

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
	<span contentEditable={false} className="text-[0px]">
		{String.fromCodePoint(160) /* Non-breaking space */}
	</span>
);

const LinkComponent = ({ attributes, children, element }: RenderElementPropsExtended) => {
	const selected = useSelected();
	return (
		<a {...attributes} href={element.url} className={cn('text-blue-700', selected && 'shadow-sm')} target="_blank" rel="noopener noreferrer">
			<InlineChromiumBugfix />
			{children}
			<InlineChromiumBugfix />
		</a>
	);
};

const isUrl = (text: string) => {
	return z.string().url().safeParse(text).success;
};

const toggleBlock = (editor: Editor, format: string) => {
	const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
	const isList = LIST_TYPES.includes(format);

	Transforms.unwrapNodes(editor, {
		// @ts-expect-error unable to find correct types
		match: (n: BaseEditor & { type: string }) =>
			!Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes((n as Record<string, string>).type) && !TEXT_ALIGN_TYPES.includes(format),
		split: true,
	});
	let newProperties: Partial<SlateElement> | Record<string, unknown>;
	if (TEXT_ALIGN_TYPES.includes(format)) {
		newProperties = {
			align: isActive ? undefined : format,
		};
	} else {
		newProperties = {
			type: isActive ? 'paragraph' : isList ? 'list-item' : format,
		};
	}
	Transforms.setNodes<SlateElement>(editor, newProperties);

	if (!isActive && isList) {
		const block = { type: format, children: [] };
		Transforms.wrapNodes(editor, block);
	}
};

const toggleMark = (editor: Editor, format: string) => {
	const isActive = isMarkActive(editor, format);

	if (isActive) {
		Editor.removeMark(editor, format);
	} else {
		Editor.addMark(editor, format, true);
	}
};

const isBlockActive = (editor: Editor, format: string, blockType = 'type') => {
	const { selection } = editor;
	if (!selection) return false;

	const [match] = Array.from(
		Editor.nodes(editor, {
			at: Editor.unhangRange(editor, selection),
			match: (n: unknown) => !Editor.isEditor(n) && SlateElement.isElement(n) && (n as unknown as Record<string, string>)[blockType] === format,
		}),
	);

	return !!match;
};

const isMarkActive = (editor: Editor, format: string) => {
	const marks = Editor.marks(editor);
	return marks ? marks[format as keyof typeof marks] === true : false;
};

interface RenderElementPropsExtended extends RenderElementProps {
	element: RenderElementProps['element'] & {
		align?: React.CSSProperties['textAlign'];
		type?: string;
		children: any[];
		character?: string;
		url?: string;
	};
	readOnly?: boolean;
}

const Mention = ({ attributes, children, element, readOnly }: RenderElementPropsExtended) => {
	const selected = useSelected();
	const focused = useFocused();

	const systemVariableValue = useSystemVariable(element.character!);

	return (
		<span
			{...attributes}
			contentEditable={false}
			className={cn(
				'inline-block rounded-sm p-[2px] align-baseline text-sm text-primary',
				!readOnly && 'border border-primary/20 bg-muted',
				element?.children?.[0]?.bold && 'font-bold',
				element?.children?.[0]?.italic && 'italic',
				element?.children?.[0]?.underline && 'underline',
				selected && focused && 'border-primary/60',
			)}
		>
			{readOnly ? systemVariableValue : element.character} {children}
		</span>
	);
};

const Element = ({ attributes, children, element, readOnly }: RenderElementPropsExtended) => {
	const align = element.align;

	const alignClassNames = cn(
		align === 'right' && 'text-right',
		align === 'left' && 'text-left',
		align === 'center' && 'text-center',
		align === 'justify' && 'text-justify',
	);
	switch (element.type) {
		case 'link':
			return <LinkComponent attributes={attributes} children={children} element={element} />;
		case 'mention':
			return <Mention attributes={attributes} children={children} element={element} readOnly={readOnly} />;
		case 'block-quote':
			return (
				<TypographyBlockquote className={cn(alignClassNames)} {...attributes}>
					{children}
				</TypographyBlockquote>
			);
		case 'bulleted-list':
			return (
				<ul className={cn(alignClassNames)} {...attributes}>
					{children}
				</ul>
			);
		case 'heading':
			return (
				<TypographyH4 className={cn(alignClassNames)} {...attributes}>
					{children}
				</TypographyH4>
			);
		case 'list-item':
			return (
				<li className={cn(alignClassNames)} {...attributes}>
					{children}
				</li>
			);
		case 'numbered-list':
			return (
				<ol className={cn(alignClassNames)} {...attributes}>
					{children}
				</ol>
			);
		default:
			return (
				<TypographyP className={cn(alignClassNames, '[&:not(:first-child)]:mt-2')} {...attributes}>
					{children}
				</TypographyP>
			);
	}
};
interface LeafProps {
	attributes: HTMLAttributes<HTMLElement>;
	leaf: {
		bold?: boolean;
		italic?: boolean;
		underline?: boolean;
	};
	children: React.ReactNode;
}
const Leaf = ({ attributes, children, leaf }: LeafProps) => {
	if (leaf.bold) {
		children = <strong>{children}</strong>;
	}

	if (leaf.italic) {
		children = <em>{children}</em>;
	}

	if (leaf.underline) {
		children = <u>{children}</u>;
	}

	return <span {...attributes}>{children}</span>;
};

interface BlockButtonProps {
	format: string;
	Icon: LucideIcon | React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
}
const BlockButton = ({ format, Icon }: BlockButtonProps) => {
	const editor = useSlate();
	const active = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
	return (
		<SlateButton
			active={active}
			onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
				event.preventDefault();
				toggleBlock(editor, format);
			}}
		>
			<Icon className={cn(active ? 'text-primary/80' : 'text-primary/40', 'h-5 w-5')} />
		</SlateButton>
	);
};

interface MarkButtonProps {
	format: string;
	Icon: LucideIcon | React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
}
const MarkButton = ({ format, Icon }: MarkButtonProps) => {
	const editor = useSlate();
	const active = isMarkActive(editor, format);
	return (
		<SlateButton
			active={active}
			onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
				event.preventDefault();
				toggleMark(editor, format);
			}}
		>
			<Icon className={cn(active ? 'text-primary/80' : 'text-primary/40', 'h-5 w-5')} />
		</SlateButton>
	);
};

interface MentionButtonProps {
	setTarget: (target: Range) => void;
	setSearch: (search: string) => void;
	setIndex: (index: number) => void;
}
function MentionButton({ setIndex, setSearch, setTarget }: MentionButtonProps) {
	const { t } = useTranslation();
	const editor = useSlate();
	const onClickMentionButton = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const { selection } = editor;
		if (selection && Range.isCollapsed(selection)) {
			const path = selection.focus.path;
			const offset = selection.focus.offset;
			const mentionRange = {
				anchor: { path, offset },
				focus: { path, offset },
			};
			setTarget(mentionRange); // set the target to the current selection
			setSearch(''); // reset search
			setIndex(0); // reset index
		}
	};
	return (
		<SlateButton onMouseDown={onClickMentionButton}>
			<span className="text-blue-700 hover:underline">{t('add_variable')}</span>
		</SlateButton>
	);
}

function LinkButton() {
	const { t } = useTranslation();
	const editor = useSlate();
	const active = isLinkActive(editor);
	const [promptOpen, setPromptOpen] = useState(false);

	const form = useForm({
		resolver: zodResolver(z.object({ link: z.string().url() })),
	});

	function setPromptVisible(value: boolean) {
		form.setValue('link', '');
		setPromptOpen(value);
	}

	return (
		<>
			<SlateButton
				active={isLinkActive(editor)}
				onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
					event.preventDefault();
					const selectedText = editor.selection && Editor.string(editor, editor.selection)?.trim();

					if (selectedText && isUrl(selectedText)) {
						form.setValue('link', selectedText);
					}
					setPromptOpen(true);
				}}
			>
				<Link2Icon className={cn(active ? 'text-primary/80' : 'text-primary/40', 'h-5 w-5')} />
			</SlateButton>
			<Dialog onOpenChange={setPromptVisible} open={promptOpen}>
				<DialogContent className="sm:max-w-[425px]">
					<DialogHeader>
						<DialogTitle>{t('enter_website_link')}</DialogTitle>
					</DialogHeader>
					<Form {...form}>
						<FormField
							control={form.control}
							name="link"
							render={({ field }) => (
								<FormItem>
									<FormLabel required>{t('website_link')}</FormLabel>
									<FormControl>
										<Input placeholder="https://example.com" {...field} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
					</Form>
					<DialogFooter>
						<Button
							type="button"
							onClick={form.handleSubmit((data) => {
								insertLink(editor, data.link);
								setPromptVisible(false);
							})}
						>
							{active ? t('save_changes') : t('insert')}
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</>
	);
}

function RemoveLinkButton() {
	const editor = useSlate();
	const active = isLinkActive(editor);

	return (
		<SlateButton
			active={isLinkActive(editor)}
			onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
				event.preventDefault();
				if (isLinkActive(editor)) {
					unwrapLink(editor);
				}
			}}
		>
			<Link2OffIcon className={cn(active ? 'text-primary/80' : 'text-primary/40', 'h-5 w-5')} />
		</SlateButton>
	);
}

type LinkElement = { type: 'link'; url: string; children: Descendant[] };
