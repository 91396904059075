import { type ClassValue, clsx } from 'clsx';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function bufferToBase64String(buffer: Uint8Array) {
	let buf = '';
	for (let i = 0; i < buffer.length; i++) {
		buf += String.fromCharCode(buffer[i]);
	}
	return btoa(buf);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const narrowForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	form: any,
) => form as unknown as UseFormReturn<TFieldValues, TContext>;

export function keepMaxDecimals(value: number, maxDecimals: number) {
	return Number(value.toFixed(maxDecimals));
}

export async function fileToBase64Content(file: File) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result?.toString().split(',')[1] ?? '');
		reader.onerror = reject;
	});
}

export async function downloadBase64File({ base64, fileName, fileType }: { base64: string; fileName: string; fileType: string }) {
	const link = document.createElement('a');
	link.href = `data:${fileType};base64,${base64}`;
	link.download = fileName;
	link.click();
}

export function humanizeFileSize(bytes: number) {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return '0 Byte';
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
}
