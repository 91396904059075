import { LinkButton } from '@/components/buttons/link-button';
import { TypographyH2, TypographyMuted } from '@/components/ui/typography';
import { useTranslation } from 'react-i18next';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export function CustomErrorPage() {
	const { t } = useTranslation();
	const error = useRouteError();

	if (isRouteErrorResponse(error)) {
		return (
			<main className="flex h-full w-full flex-col items-center justify-center gap-y-4">
				{error?.status === 404 ? <TypographyH2>{t('page_not_found')}</TypographyH2> : <TypographyH2>{t('internal_error')}</TypographyH2>}
				<LinkButton to="/">{t('return_to_home')}</LinkButton>
				<TypographyMuted>{error.data}</TypographyMuted>
			</main>
		);
	}
	return (
		<main className="flex h-full w-full flex-col items-center justify-center gap-y-4">
			<TypographyH2>{t('internal_error')}</TypographyH2>
			<LinkButton to="/">{t('return_to_home')}</LinkButton>
		</main>
	);
}
