import { OperationLink, TRPCClientError, TRPCClientRuntime, TRPCLink, createTRPCReact, httpBatchLink, loggerLink } from '@trpc/react-query';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AppRouter } from '@timp/server/src/router';
import { useState } from 'react';
import { TRPCClientErrorLike } from '@trpc/react-query';
import { observable, tap } from '@trpc/server/observable';
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { TRPCUseQueries } from '@trpc/react-query/shared';

// eslint-disable-next-line react-refresh/only-export-components
export const trpc = createTRPCReact<AppRouter>({
	overrides: {
		useMutation: {
			/**
			 * This function is called whenever a `.useMutation` succeeds
			 **/
			async onSuccess(opts) {
				/**
				 * @note that order here matters:
				 * The order here allows route changes in `onSuccess` without
				 * having a flash of content change whilst redirecting.
				 **/
				// Calls the `onSuccess` defined in the `useQuery()`-options:
				await opts.originalFn();
				// Invalidate all queries in the react-query cache:
				await opts.queryClient.invalidateQueries();
			},
		},
	},
});

interface Props {
	children: React.ReactNode;
}

function getLocale() {
	return localStorage.getItem('i18nextLng') || 'en';
}

export function TRPCProvider({ children }: Props) {
	const { t } = useTranslation();

	const errorLink: TRPCLink<AppRouter> = (_opts: TRPCClientRuntime): OperationLink<AppRouter> => {
		const link: OperationLink<AppRouter> = ({ op, next }) => {
			return observable((observer) => {
				next(op)
					.pipe(
						tap({
							error: (result) => {
								if (result.data?.code !== 'UNAUTHORIZED') {
									toast.error(result.message, {
										id: result.message,
										action: {
											label: t('close'),
											onClick: () => void 0,
										},
									});
								}
							},
						}),
					)
					.subscribe(observer);
			});
		};
		return link;
	};

	const [queryClient] = useState(
		() =>
			new QueryClient({
				defaultOptions: {
					queries: {
						retry(failureCount: number, error: unknown) {
							if (!(error instanceof TRPCClientError)) {
								return false;
							}

							const retryableCodes = new Set(['TIMEOUT', 'INTERNAL_SERVER_ERROR', 'TOO_MANY_REQUESTS']);
							if (error?.data?.code && !retryableCodes.has(error.data.code)) {
								return false;
							}

							return failureCount < 3;
						},
					},
				},
			}),
	);
	const [trpcClient] = useState(() =>
		trpc.createClient({
			links: [
				loggerLink({
					enabled: (opts) =>
						(process.env.NODE_ENV === 'development' && typeof window !== 'undefined') || (opts.direction === 'down' && opts.result instanceof Error),
				}),
				errorLink,
				httpBatchLink({
					url: '/api/rpc',
					headers: async () => {
						return {
							'x-locale': getLocale(),
						};
					},
				}),
			],
		}),
	);
	return (
		<trpc.Provider client={trpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</trpc.Provider>
	);
}

export type TRPCError = TRPCClientErrorLike<AppRouter>;

export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;
export type UseQueries = TRPCUseQueries<AppRouter>;
