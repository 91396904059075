import { useState, useCallback } from 'react';

type UseDragProps = {
	onDrag: (dropElementIdx: number, elementIdx: number) => void;
	currentIndex: number;
	group?: string;
};

let currentGroup = '';

export const useDrag = ({ onDrag, currentIndex, group = 'default' }: UseDragProps) => {
	const [dragCount, setDragCount] = useState(0);
	const [allowDrag, setAllowDrag] = useState(false);

	const onDrop = useCallback(
		(ev: React.DragEvent) => {
			ev.preventDefault();
			const dropElementIdx = parseInt(ev.dataTransfer.getData('elementIdx'));

			if (currentGroup === group && !isNaN(dropElementIdx)) {
				onDrag(dropElementIdx, currentIndex);
				setDragCount((cnt) => cnt - 1);
			}
		},
		[onDrag, currentIndex, group],
	);

	const onDragStart = useCallback(
		(ev: React.DragEvent) => {
			if (!allowDrag) {
				ev.preventDefault();
			} else {
				ev.dataTransfer.setData('elementIdx', currentIndex.toString());
				currentGroup = group;
			}
		},
		[allowDrag, currentIndex, group],
	);

	const onDragOver = (ev: React.DragEvent) => {
		ev.preventDefault();
	};

	const onDragEnter = () => {
		if (currentGroup === group) {
			setDragCount((cnt) => cnt + 1);
		}
	};

	const onDragLeave = () => {
		if (currentGroup === group) {
			setDragCount((cnt) => cnt - 1);
		}
	};
	const onMouseDown = (ev: React.MouseEvent) => {
		let currentElement: HTMLElement | null = ev.target as HTMLElement;
		let allowDrag = true;
		// Traverse up the DOM tree
		while (currentElement) {
			// Check if the current element has the 'no-drag' class
			if (currentElement.classList.contains('no-drag')) {
				allowDrag = false;
				break;
			}
			// Move up to the parent element
			currentElement = currentElement.parentElement;
		}
		setAllowDrag(allowDrag);
	};

	return {
		draggableProps: {
			draggable: true,
			onDrop,
			onDragStart,
			onDragOver,
			onDragEnter,
			onDragLeave,
			onMouseDown,
		},
		isDragging: dragCount > 0,
	};
};
