import * as Sentry from '@sentry/react';

export function initSentry() {
	Sentry.init({
		dsn: 'https://322ec1e31467a22ec334006f57432fbb@o4507905649475584.ingest.de.sentry.io/4507905679163472',
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		tracesSampleRate: 1.0,
		// Capture Replay for 100% of sessions with an error
		replaysOnErrorSampleRate: 1.0,
		// Only enable Sentry in production
		enabled: import.meta.env.VITE_ENVIRONMENT === 'PRODUCTION',
	});
}
