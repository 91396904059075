import React, { useEffect } from 'react';
import { Form } from '@/components/ui/form';
import { Button } from '@/components/ui/button';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, useFieldArray } from 'react-hook-form';
import { useState } from 'react';
import { FormViewerSectionContent } from './form-viewer-section-content';
import type { FormViewerModes, FormViewerShemaType } from './form-viewer-types';
import { useTranslation } from 'react-i18next';
import { formTemplatesCommonViewFormContentInputSchema } from '@timp/server/src/schemas/form-templates-common.schema';
import {
	FormBaseMainFooter,
	FormBaseLayout,
	FormBaseMain,
	FormBaseMainContent,
	FormBaseSidebar,
	FormBaseSidebarSectionButton,
	FormBaseMainHeader,
	FormBaseSectionTitle,
} from '../form-builders/form-base-layout';
import { TypographyMuted } from '../ui/typography';

type BaseProps = {
	form: FormViewerShemaType;
};

type RegularFormViewerMode = Exclude<FormViewerModes, 'print'>;
type Props<TMode extends RegularFormViewerMode = RegularFormViewerMode> = BaseProps &
	// Props for submit mode
	(| {
				mode: Extract<TMode, 'submit'>;
				isSubmitting: boolean;
				onSubmit: (form: FormViewerShemaType) => void;
				onDirtyChange?: (isDirty: boolean) => void;
		  }
		// Props for page mode
		| {
				mode: Extract<TMode, 'page'>;
				onBack?: (data: FormViewerShemaType) => void;
				onNext?: (data: FormViewerShemaType) => void;
				onDirtyChange?: (isDirty: boolean) => void;
		  }
		// Props for preview-builder mode
		| {
				mode: Extract<TMode, 'preview-builder'>;
		  }
		// Props for answers-view mode
		| {
				mode: Extract<TMode, 'answers-view'>;
		  }
	);

export function FormViewer(props: Props) {
	const { t } = useTranslation();

	const [activeSectionIdx, setActiveSectionIdx] = useState(0);

	const form = useForm<FormViewerShemaType>({
		resolver: zodResolver(formTemplatesCommonViewFormContentInputSchema),
		values: props.form,
	});
	const errors = form.formState.errors;

	const { control } = form;

	const { fields: sections } = useFieldArray({
		control,
		name: 'sections',
	});

	const activeSection = sections[activeSectionIdx];
	const isFirstSection = activeSectionIdx === 0;
	const isLastSection = activeSectionIdx === sections.length - 1;

	const isPageMode = props.mode === 'page';
	const isSubmitMode = props.mode === 'submit';
	const isSingleSection = sections.length === 1;

	const hideBackButton = !isPageMode && isSingleSection;
	const hideNextButton = !isPageMode && isSingleSection;
	const hideSubmitButton = !isSubmitMode;

	const disableBackButton = (isPageMode && !props.onBack && isFirstSection) || (!isPageMode && isFirstSection) || (isSubmitMode && props.isSubmitting);
	const disableNextButton = (isPageMode && !props.onNext && isLastSection) || (!isPageMode && isLastSection) || (isSubmitMode && props.isSubmitting);

	const handleBackClick = () => {
		if (isPageMode && props.onBack && isFirstSection) {
			props.onBack(form.getValues());
			return;
		}
		setActiveSectionIdx((c) => Math.max(c - 1, 0));
	};

	const handleNextClick = () => {
		if (isPageMode && props.onNext && isLastSection) {
			form.trigger().then((isValid) => {
				if (isValid) props?.onNext?.(form.getValues());
			});
			return;
		}
		setActiveSectionIdx((c) => Math.min(c + 1, sections.length - 1));
	};

	/**
	 * Focus the first element with an error when the form is submitted
	 */
	useEffect(() => {
		const sectionWithErrorIdx = errors?.sections?.findIndex?.((section) => !!section);
		if (typeof sectionWithErrorIdx === 'number') {
			const element = errors?.sections?.at?.(sectionWithErrorIdx)?.elements?.find?.((el) => !!el);
			if (element) {
				const el = element?.root ?? element;
				setActiveSectionIdx(sectionWithErrorIdx);
				el?.ref?.focus?.();
			}
		}
	}, [errors]);

	/**
	 * Call the onDirtyChange callback if it is provided
	 */
	const onDirtyChange = props.mode === 'submit' ? props.onDirtyChange : undefined;
	useEffect(() => {
		if (onDirtyChange) {
			onDirtyChange(form.formState.isDirty);
		}
	}, [form.formState.isDirty, onDirtyChange]);

	// Render nothing if there's no sections
	if (sections.length === 0) {
		return null;
	}

	return (
		<Form {...form}>
			<form onSubmit={props.mode === 'submit' ? form.handleSubmit(props.onSubmit) : (e) => e.preventDefault()} className="flex flex-1 flex-col overflow-hidden">
				<FormBaseLayout>
					{sections.length > 1 && (
						<FormBaseSidebar className="hidden md:flex">
							{sections.map((section, index) => {
								return (
									<FormBaseSidebarSectionButton
										key={section.id}
										onClick={() => setActiveSectionIdx(index)}
										isActive={index === activeSectionIdx}
										sectionIdx={index}
									>
										{section.name}
									</FormBaseSidebarSectionButton>
								);
							})}
						</FormBaseSidebar>
					)}

					<FormBaseMain>
						<FormBaseMainContent>
							<FormBaseMainHeader>
								<div className="flex items-center justify-between" key={activeSection?.id}>
									<FormBaseSectionTitle>{activeSection?.name}</FormBaseSectionTitle>
									{sections.length > 1 && (
										<TypographyMuted className="min-w-24 text-nowrap md:hidden">
											({t('page')} {activeSectionIdx + 1}/{sections.length})
										</TypographyMuted>
									)}
								</div>
							</FormBaseMainHeader>
							{sections.length > 0 && <FormViewerSectionContent key={activeSectionIdx} sectionIndex={activeSectionIdx} control={control} mode={props.mode} />}
						</FormBaseMainContent>

						<FormBaseMainFooter>
							<div className="flex w-full justify-end gap-x-2">
								{!hideBackButton && (
									<Button type="button" variant="outline" disabled={disableBackButton} onClick={handleBackClick}>
										{t('back')}
									</Button>
								)}
								{!hideNextButton && (
									<Button type="button" variant={hideSubmitButton ? 'default' : 'outline'} disabled={disableNextButton} onClick={handleNextClick}>
										{t('next')}
									</Button>
								)}
								{!hideSubmitButton && (
									<Button type="submit" isLoading={props.isSubmitting}>
										{t('submit')}
									</Button>
								)}
							</div>
						</FormBaseMainFooter>
					</FormBaseMain>
				</FormBaseLayout>
			</form>
		</Form>
	);
}
