import en from './locales/en/translation.json';
import no from './locales/no/translation.json';
import enZod from './locales/en/zod.json';
import noZod from './locales/no/zod.json';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { makeZodI18nMap } from 'zod-i18n-map';
import z from 'zod';

export const LANGUAGE_CODES = ['en', 'no'];

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources: {
			en: { translation: en, zod: enZod },
			no: { translation: no, zod: noZod },
		},
		supportedLngs: ['en', 'no'],
		fallbackLng: 'en',
		detection: {
			order: ['localStorage', 'navigator'],
			convertDetectedLanguage: (lng: string) => {
				if (lng === 'nn-NO') return 'no';
				if (lng === 'nb-NO') return 'no';
				return lng;
			},
		},
		debug: false,
		returnNull: false,
		ns: ['translation'],
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
	});

z.setErrorMap(makeZodI18nMap({ ns: 'zod' }));

export default i18n;
